<template>
    <div>
        <section class="tp-banner">
            <div >
                <div id="rev_slider_1078_1" class="rev_slider fullwidthabanner" >
                    <carousel :per-page="1" :autoplay="true" :loop="true" :autoplayTimeout="10000" >
                        <slide>
                            <img src="images/slider/imgg1.jpeg"  alt=""  >
                        </slide>
                        <slide>
                            <img src="images/slider/imgg2.jpeg"  alt=""  >
                        </slide>
                        <slide>
                            <img src="images/slider/imgg3.jpeg"  alt=""  >
                        </slide>

                        <!--<slide>
                            <img src="images/slider/image4.png"  alt=""  >
                        </slide>
                        <slide>
                            <img src="images/slider/image5.png"  alt=""  >
                        </slide>
                        <slide>
                            <img src="images/slider/image6.png"  alt=""  >
                        </slide>

                        <slide>
                            <img src="images/slider/image7.png"  alt=""  >
                        </slide> -->
                    </carousel>
                </div>
            </div>
            <section class="sdl-booking">
                <ul class="tab_booking">
                    <li :class="{active: activeForm == 'one'}" @click="activeForm = 'one'"><a href="javascript:void">Airport/Transfers</a></li>
                    <li :class="{active: activeForm == 'two'}" @click="activeForm = 'two'"><a href="javascript:void">Events</a></li>
                    <li><router-link :to="{name: 'packages'}">Packages</router-link></li>
                </ul>
                <div id="bk-1" class="schedule-booking fw" :style="{display: activeForm == 'one' ? 'block' : 'none'}">
                    <form class="form-booking" method="get" @submit.prevent>
                        <div class="pick-address js width1">
                            <label>Pick Up Address</label>
                            <vue-google-autocomplete
                                id="pickupLocation"
                                classname="form-control"
                                placeholder="Search location"
                                v-on:placechanged="getPickupAddressData"
                                :value="defaultPickupAddress"
                                types=""
                                :country="['us', 'ca']"
                            >
                            </vue-google-autocomplete>
                            <span @click="showOptionalStop = !showOptionalStop" class="extraBtn">
                                <i :class="['fa', {'fa-plus': !showOptionalStop}, {'fa-minus': showOptionalStop}]"></i>
                            </span>
                            
                        </div>
                        <div class="pick-dropday js width1">
                            <label>Drop Off Address</label>
                            <vue-google-autocomplete
                                id="dropoffLocation"
                                classname="form-control"
                                placeholder="Search location"
                                v-on:placechanged="getDropoffAddressData"
                                :value="defaultDropoffAddress"
                                types=""
                                :country="['us', 'ca']"
                            >
                            </vue-google-autocomplete>
                        </div>

                        <div class="width3">
                            <label for="number-pass">Round Trip</label>
                            <div class="select">
                            <select
                                name="number-pass"
                                id="number-pass"
                                v-model="isReturnWay"
                            >
                                <option :value="true">Yes</option>
                                <option :value="false">No</option>
                            </select>
                            </div>
                        </div>

                        <div class="pick-date width2">
                            <label>Pick Up Date</label>
                            <date-picker
                                v-model="pickUpDate"
                                format="YYYY-MM-DD"
                                type="date"
                                value-type="MM/DD/YYYY"
                                placeholder="Select date"
                                :disabled-date="$disabledBeforeToday"
                            ></date-picker>
                        </div>
                        <div class="pick-time js width2">
                            <label>Pick Up Time</label>
                            <date-picker
                                v-model="pickUpTime"
                                format="hh:mm A"
                                type="time"
                                :minute-step="15"
                                value-type="hh:mm A"
                                placeholder="Select Time"
                                :open.sync="openOnewayTimer"
                                @change="closeTimer"
                            ></date-picker>
                        </div>
                        <div class="btn-submit">
                            <a href="javascript:void(0)" @click="retrieveInfo()" class="register_now">Reserve Now<img src="images/icon/arrow-white.png" alt=""></a>
                        </div>
                    </form>
                </div>
                <div id="bk-1" class="schedule-booking fw" :style="{display: activeForm == 'two' ? 'block' : 'none'}">
                    <form class="form-booking" method="get" @submit.prevent>
                        <div class="pick-address js width4">
                            <label>Pick Up Address</label>
                            <vue-google-autocomplete
                                id="hourPickupLocation"
                                classname="form-control"
                                placeholder="Search location"
                                v-on:placechanged="getHourPickupAddressData"
                                :value="defaultHourPickupAddress"
                                types=""
                                :country="['us', 'ca']"
                            >
                            </vue-google-autocomplete>                            
                        </div>
                        <div class="pick-date width2">
                            <label>Pick Up Date</label>
                            <date-picker
                                v-model="hourPickUpDate"
                                format="YYYY-MM-DD"
                                type="date"
                                value-type="MM/DD/YYYY"
                                placeholder="Select date"
                                :disabled-date="$disabledBeforeToday"
                            ></date-picker>
                        </div>
                        <div class="pick-time js width2">
                            <label>Pick Up Time</label>
                            <date-picker
                                v-model="hourPickUpTime"
                                format="hh:mm A"
                                type="time"
                                :minute-step="15"
                                value-type="hh:mm A"
                                placeholder="Select Time"
                                :open.sync="openHourTimer"
                                @change="closeHourTimer"
                            ></date-picker>
                        </div>
                        <div class="pick-time js width3">
                            <label>For Hours?</label>
                                <select v-model="selectedHour">
                                    <option value="">Select Hours</option>
                                    <option v-for="hour in 12" :key="hour" :value="hour" v-if="hour >= 2">{{hour}} hours</option>
                                </select>
                        </div>
                        <div class="btn-submit">
                            <a href="javascript:void(0)" @click="retrieveHourInfo()" class="register_now">Reserve Now<img src="images/icon/arrow-white.png" alt=""></a>
                        </div>
                    </form>
                </div>
            </section>
        </section>
        <div v-if="showOptionalStop" class="posRelative">
            <vue-google-autocomplete
                id="stopLocation"
                classname="form-control"
                placeholder="Search location"
                v-on:placechanged="getStopAddressData"
                :value="defaultStopAddress"
                :country="['us', 'ca']"
                types=""
            >
            </vue-google-autocomplete>
            <i class="fa fa-close closeIcon" @click="clearStopLocation()"></i>
        </div>

        <div v-if="isReturnWay" class="roundTripContainer form-booking">
            <div class="flex-item pick-date width2">
                <label>Return Pick Up Date</label>
                <date-picker
                    v-model="returnPickUpDate"
                    format="YYYY-MM-DD"
                    type="date"
                    value-type="MM/DD/YYYY"
                    placeholder="Select date"
                    :disabled-date="$disabledBeforeToday"
                ></date-picker>
            </div>
            <div class="flex-item pick-time js width2">
                <label>Return Pick Up Time</label>
                <date-picker
                    v-model="returnPickUpTime"
                    format="hh:mm A"
                    type="time"
                    :minute-step="15"
                    value-type="hh:mm A"
                    placeholder="Select Time"
                    :open.sync="openReturnwayTimer"
                    @change="closeReturnTimer"
                ></date-picker>
            </div>
        </div>
        <section class="template-title has-over  top60"  id="services">
            <div class="container">
                <h3 class="title">Our Services</h3>
                <span class="title_over">Our Services</span>
                <p class="subtitle">Sit Back, Relax, And Enjoy the Ride</p>
            </div>
        </section>
        <!-- nd Template Title -->
        <!-- Start Icon box -->
        <section class="icon-box">
            <div class="container">
                <ul class="tab_services">
                    <li :class="{active: ourService === 'one'}"><a href="javascript:void(0)" @click="ourService = 'one'">
                        <img src="/images/icon/forma1.png" alt="">
                        Corporate Service
                    </a></li>
                    <li :class="{active: ourService === 'two'}"><a href="javascript:void(0)" @click="ourService = 'two'">
                        <img src="/images/icon/forma2.png" alt="">
                        VIP Service
                    </a></li>
                    <li :class="{active: ourService === 'three'}"><a href="javascript:void(0)" @click="ourService = 'three'">
                        <img src="/images/icon/forma3.png" alt="">
                        Party Service
                    </a></li>
                </ul>
                <div id="sv-1" class="content-box" :style="{display: ourService === 'one' ? 'block' : 'none'}">
                    <div class="position-content">
                        <p class="left-content">The exclusive way to travel</p>
                        <p class="center-content">Enjoy the comfort of our limousines and luxury transport, preparing you for your next business meeting in peace.</p>
                        <p class="right-content">No matter the situation – you can fully rely on your chauffeur and reach your destination safely. Step right in, sit back, and relax. We will handle everything else.</p>
                    </div>
                    <div class="view_service">
                        <router-link :to="{ name: 'packages' }">More Service<img src="/images/icon/arrow-next.png" alt=""></router-link>
                    </div>
                </div>
                <div id="sv-2" class="content-box" :style="{display: ourService === 'two' ? 'block' : 'none'}">
                    <div class="position-content">
                        <p class="left-content">The exclusive way to travel</p>
                        <p class="center-content">Looking for a great wedding celebration? Or are you attending a party and would like to arrive in style? Prepare for your next venture with us, ensuring an effortless experience you’ll never forget!</p>
                        <p class="right-content">No matter the situation – you can fully rely on your chauffeur and reach your destination safely. Step right in, sit back, and relax. We will handle everything else.</p>
                    </div>
                    <div class="view_service">
                        <router-link :to="{ name: 'packages' }">More Service<img src="/images/icon/arrow-next.png" alt=""></router-link>
                    </div>
                </div>
                <div id="sv-3" class="content-box" :style="{display: ourService === 'three' ? 'block' : 'none'}">
                    <div class="position-content">
                        <p class="left-content">The exclusive way to travel</p>
                        <p class="center-content">Are you and your friends looking to go out and have a good weekend, all while staying safe? Allow our professional chauffeurs to transport you to and from the desired location eliminating any dangers from getting distracted during such an exciting time!</p>
                        <p class="right-content">No matter the situation – you can fully rely on your chauffeur and reach your destination safely. Step right in, sit back, and relax. We will handle everything else.</p>
                    </div>
                    <div class="view_service">
                        <router-link :to="{ name: 'packages' }">More Service<img src="/images/icon/arrow-next.png" alt=""></router-link>
                    </div>
                </div>
            </div>
            <hr>
        </section>
        <section class="info-box parallax parallax_one">
            <div class="container">
                <div class="wrapper-content">
                    <h3 class="title">
                        Or anywhere you need us to take you
                    </h3>
                    <p class="content">Although we offer Corporate Service, VIP Service, and Party Services, you are by no means limited to these! If you’d like, feel free to use your imagination and request a ride to wherever you’d like to go, and we’d be more than happy to turn your dreams into reality!</p>
                    <a href="javascript:void(0)" @click="$scrollToTop()" class="booking">Online Booking<img src="/images/icon/arrow-white.png" alt=""></a>
                    <p class="contact mt-5">Call Now <a href="tel:877-206-0780" style="color:#fff">877-206-0780 </a></p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import 'vue2-datepicker/index.css';
import { mapGetters } from "vuex";
import { Carousel, Slide } from 'vue-carousel';

export default {
	components: { 
		DatePicker,
		VueGoogleAutocomplete,
        Carousel,
        Slide
	},
	data(){
		return {
			activeLocationForm: false,
            activeForm: 'one',
            isReturnWay: false,
			pickUpDate: '',
			pickUpTime: '',
            returnPickUpDate: '',
			returnPickUpTime: '',
            pickUpLocation: {},
            stopLocation: {},
            dropOffLocation: {},
            defaultPickupAddress: '',
            defaultDropoffAddress: '',
            defaultStopAddress: '',
            ourService: 'one',
            showOptionalStop: false,
            openOnewayTimer: false,
            openReturnwayTimer: false,
            openHourTimer: false,
            selectedHour: '',

            hourPickUpDate: '',
			hourPickUpTime: '',
            hourPickUpLocation: {},
            defaultHourPickupAddress: '',
            
		}
	},
    computed:{
		...mapGetters(['booking'])
	},
    watch: {
        isReturnWay(newVal, oldVal){
            if(!this.isReturnWay){
                this.returnPickUpDate = '';
                this.returnPickUpTime = '';
            }
        },
        activeForm(){
            if(this.activeForm === 'two'){
                this.isReturnWay = false
                this.returnPickUpDate = '';
                this.returnPickUpTime = '';
            }
        }
    },
    methods:{
        clearStopLocation(){
            this.stopLocation = {};
            this.defaultStopAddress = '';
            this.showOptionalStop = false;
        },
        getHourPickupAddressData(addressData, placeData){
            this.hourPickUpLocation = {
                lat: addressData.latitude,
                lng: addressData.longitude,
                address: addressData.route,
                formattedAddress: placeData.formatted_address
            }
        },
        getPickupAddressData(addressData, placeData){
            this.pickUpLocation = {
                lat: addressData.latitude,
                lng: addressData.longitude,
                address: addressData.route,
                formattedAddress: placeData.formatted_address
            }
        },
        getStopAddressData(addressData, placeData){
            this.stopLocation = {
                lat: addressData.latitude,
                lng: addressData.longitude,
                address: addressData.route,
                formattedAddress: placeData.formatted_address
            }
        },
        getDropoffAddressData(addressData, placeData){
            this.dropOffLocation = {
                lat: addressData.latitude,
                lng: addressData.longitude,
                address: addressData.route,
                formattedAddress: placeData.formatted_address
            }
        },
        retrieveInfo(){
            if(Object.keys(this.pickUpLocation).length == 0){
                this.$limotoast.warning('Select Pick Up Address');
                return;
            }
            if(Object.keys(this.dropOffLocation).length == 0){
                this.$limotoast.warning('Select Drop Off Address');
                return;
            }
            if(this.pickUpLocation.lat == this.dropOffLocation.lat && this.pickUpLocation.lng == this.dropOffLocation.lng){
                this.$limotoast.warning('Pick up and drop off location must not be the same');
                return;
            }

            if(this.pickUpDate == ""){
                this.$limotoast.warning('Select Pick Up Date');
                return;
            }

            if(this.pickUpTime == ""){
                this.$limotoast.warning('Select Pick Up Time');
                return;
            }

            var pickupDateTime = `${this.pickUpDate} ${this.pickUpTime}`;
            if(this.$moment().isAfter(this.$moment(pickupDateTime))){
                this.$limotoast.warning('Please select future time!');
                return false;
            }
            var resultInMinutes = this.$getTimeDifferenceInMinute(pickupDateTime, new Date());
            if (resultInMinutes < 91) {
                this.$limotoast.warning('You Can Only Book After 90 minutes From Current Time!');
                return false;
            }

            if(this.isReturnWay){
                if(this.returnPickUpDate == ""){
                    this.$limotoast.warning('Select Return Pick Up Date');
                    return;
                }
                if(this.returnPickUpTime == ""){
                    this.$limotoast.warning('Select Return Pick Up Date');
                    return;
                }
                var returnPickupDateTime = `${this.returnPickUpDate} ${this.returnPickUpTime}`;
                if(this.$moment(pickupDateTime).isAfter(this.$moment(returnPickupDateTime))){
                    this.$limotoast.warning('Please select future date time for return!');
                    return false;
                }
            }

            const location = {
                pickUpLocation: this.pickUpLocation,
                dropOffLocation: this.dropOffLocation,
                stopLocation: this.stopLocation,
                pickUpTime: this.pickUpTime,
                pickUpDate: this.pickUpDate,
                type: process.env.VUE_APP_ONEWAY_TRIP,
                isReturnWay: this.isReturnWay,
                returnPickUpDate: this.returnPickUpDate,
                returnPickUpTime: this.returnPickUpTime
            };
            this.$store.commit('setTripLocation', location);
            this.$router.push({name: 'booking'});
        },

        retrieveHourInfo(){
            if(Object.keys(this.hourPickUpLocation).length == 0){
                this.$limotoast.warning('Select Pick Up Address');
                return;
            }

            if(this.hourPickUpDate == ""){
                this.$limotoast.warning('Select Pick Up Date');
                return;
            }

            if(this.hourPickUpTime == ""){
                this.$limotoast.warning('Select Pick Up Time');
                return;
            }
            if(this.hourPickUpDate == ""){
                this.$limotoast.warning('Select Pick Up Date');
                return;
            }

            let pickupDateTime = `${this.hourPickUpDate} ${this.hourPickUpTime}`;
            if(this.$moment().isAfter(this.$moment(pickupDateTime))){
                this.$limotoast.warning('Please select future time!');
                return false;
            }
            let resultInMinutes = this.$getTimeDifferenceInMinute(pickupDateTime, new Date());
            if (resultInMinutes < 91) {
                this.$limotoast.warning('You Can Only Book After 90 minutes From Current Time!');
                return false;
            }

            if(this.selectedHour == ""){
                this.$limotoast.warning('Select hours');
                return;
            }

            const location = {
                pickUpLocation: this.hourPickUpLocation,
                pickUpTime: this.hourPickUpTime,
                pickUpDate: this.hourPickUpDate,
                selectedHour: this.selectedHour,
                type: process.env.VUE_APP_HOUR_TRIP
            };
            this.$store.commit('setTripLocation', location);
            this.$router.push({name: 'booking'});
        },
        closeTimer(value, type) {
            console.log(type)
            if (type === 'ampm') {
                this.openOnewayTimer = false;
            }
        },
        closeReturnTimer(value, type){
            if (type === 'ampm') {
                this.openReturnwayTimer = false;
            }
        },
        closeHourTimer(value, type) {
            if (type === 'ampm') {
                this.openHourTimer = false;
            }
        },
	},
    mounted(){
        if(this.$route.query.options){
            const queryOption = JSON.parse(decodeURIComponent(this.$route.query.options));
            if(queryOption.type == process.env.VUE_APP_ONEWAY_TRIP){
                const location = {
                    pickUpLocation: queryOption.pickUpLocation,
                    dropOffLocation: queryOption.dropOffLocation,
                    stopLocation: this.stopLocation,
                    pickUpTime: queryOption.pickUpTime,
                    pickUpDate: queryOption.pickUpDate,
                    type: process.env.VUE_APP_ONEWAY_TRIP
                };
                this.$store.commit('setTripLocation', location);
                this.activeForm = 'one';
            } else if(queryOption.type == process.env.VUE_APP_HOUR_TRIP){
                const location = {
                    pickUpLocation: queryOption.pickUpLocation,
                    pickUpTime: queryOption.pickUpTime,
                    pickUpDate: queryOption.pickUpDate,
                    selectedHour: queryOption.selectedHour,
                    type: process.env.VUE_APP_HOUR_TRIP
                };
                this.$store.commit('setTripLocation', location);
                this.activeForm = 'two';
            }
        }
        
        if(this.booking.location){
            if(this.booking.location.type == process.env.VUE_APP_HOUR_TRIP){
                let pickUp = this.booking.location.pickUpLocation;
                this.defaultHourPickupAddress = this.booking.location.pickUpLocation.formattedAddress;
                this.hourPickUpLocation = {
                    lat: pickUp.lat,
                    lng: pickUp.lng,
                    address: pickUp.address,
                    formattedAddress: pickUp.formattedAddress
                }
                this.hourPickUpDate = this.booking.location.pickUpDate;
                this.hourPickUpTime = this.booking.location.pickUpTime;
                this.selectedHour = this.booking.location.selectedHour;
                this.activeForm = 'two';
            }else{
                let dropOff = this.booking.location.dropOffLocation;
                let pickUp = this.booking.location.pickUpLocation;
                let stop = this.booking.location.stopLocation;
    
                this.defaultPickupAddress = this.booking.location.pickUpLocation.formattedAddress;
                this.defaultDropoffAddress = this.booking.location.dropOffLocation.formattedAddress;
                this.defaultStopAddress = stop.formattedAddress;
                this.pickUpLocation = {
                    lat: pickUp.lat,
                    lng: pickUp.lng,
                    address: pickUp.address,
                    formattedAddress: pickUp.formattedAddress
                }
                this.dropOffLocation = {
                    lat: dropOff.lat,
                    lng: dropOff.lng,
                    address: dropOff.address,
                    formattedAddress: dropOff.formattedAddress
                }

                if(Object.keys(stop).length){
                    this.stopLocation = {
                        lat: stop.lat,
                        lng: stop.lng,
                        address: stop.address,
                        formattedAddress: stop.formattedAddress
                    }
                    this.showOptionalStop = true;
                }
                this.pickUpDate = this.booking.location.pickUpDate;
                this.pickUpTime = this.booking.location.pickUpTime;

                this.returnPickUpDate = this.booking.location.returnPickUpDate;
                this.returnPickUpTime = this.booking.location.returnPickUpTime;
                this.isReturnWay = this.booking.location.isReturnWay;
                this.activeForm = 'one';
            }
		}

        
        console.log(this.$route)
    }
}
</script>

<style scoped>
select{
    --webkit-appearance: auto;
    appearance: auto;

}
.mx-datepicker{
    width: auto !important;
}
.width1 {
    width: 19% !important;
}
.width2 {
    width: 15% !important;
}
.width3 {
    width: 12% !important;
}
.width4 {
    width: 38% !important;
}
.closeIcon {
    position: absolute;
    top: 16px;
    right: 10px;
    cursor: pointer;
}
.posRelative{
    position: relative;
}
</style>
<style >

.mx-datepicker-popup{
    overflow: hidden !important;
}
.roundTripContainer{
    display: flex;
    justify-content: center;
}
.roundTripContainer .flex-item{
    background: #1e1e1e;
    padding: 0px 30px;
}
</style>
